body.active-modal {
    overflow-y: hidden;
}


.about-modal, .about-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.about-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;  
    background: rgba(255, 254, 254, 0.4);
    z-index: 5;
}
.about-modal-content {
    position:relative;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding:1vw 1vw 1vw 3vw;
    box-shadow: 0 0 10px rgba(167, 167, 167, 0.2);
    width:65vw;
    height:50vw;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.close-about-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.open-about-modal{
    font-family:"IBM Plex Sans";
    font-size:3em;
    font-weight:500;

}
.open-about-modal:hover{
    color:white;
    background-color:blue;
    cursor: pointer;
    transition:color 0.5s, background-color 0.5s; 
    
}


.about-title{
    font-family:"IBM Plex Sans";
    font-weight:700;
    font-size:6vh;
    color:white;
    background-color: blue;
    width:fit-content;
    height:auto;
    padding:1vh;
}


.close-about-modal{
  font-family: "IBM Plex Sans";
  border:none;
  background-color: white;
  color:rgb(183, 183, 183);
  font-size: 3.5vh;
  font-weight:400;
}
.close-about-modal:hover{
  color:black;
  font-weight: 800;
  transition: all 1s ease;

}
.about-header-body{
    font-family:"IBM Plex Sans";
    color:Black;
    font-size: 3vw;
    font-weight:600;
}
.about-portrait{
    height:30vh;
    width:auto;
    border:.25vh dotted black;
}
.about-header-container{
    display:flex;
    flex-direction: row;
    width:45vw;
    height:fit-content;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin:0 auto;
}
.about-body-container{
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 2vh;
    width:50vw;
}
.about-statment{
    text-align: justify;
}
.about-statment-2{
    padding:2vw 0 0 0;
    text-align: justify;
    width:35vw;
    margin:1vw;
}
.ram-quote{
    color:blue;
    padding:1.5vw;
    font-weight: 800;
    font-size: 4vh;
    width:20vw;
    margin:1vh 0 0 8vw;
}
.about-statment-3{
    padding:2vw 0 0 0;
    text-align: justify;
    width:40vw;
    margin:0 0 0 5vw;

}
.skills-header{
    font-family:"IBM Plex Sans";
    color:white;
    background-color: blue;
    padding:1vh;
    font-size: 3vh;
    font-weight:700;
    width:fit-content;
    margin:4vw 0 0 0;
}

.skills-container{
    width:45vw;
    display: flex;
    flex-direction: row;
    margin:.5vh 0 3vh 4vw;
    justify-content: center;
    align-items: center;
}


.skill-icon{
    width:auto;
    height:3.25vw;
    padding:2vw;
    
}

.skill {
    position: relative;
    display: inline-block;
    margin:1vw;
  }
  
  .skill-icon {
    position: relative;
  }
  
  .skill-name {
    position: absolute;
    font-family: "IBM Plex Sans";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: black;
    background-color: rgb(255, 255, 255, 0.2);
    font-size:3vh;
    font-weight:600;
    transition: opacity 0.3s ease;
  }
  
  .skill:hover .skill-name {
    opacity: 1;
  }

  .soft-skills-header{
    font-family:"IBM Plex Sans";
    color:white;
    background-color: blue;
    padding:1vh;
    font-size: 3vh;
    font-weight:700;
    width:fit-content;
    margin:4vw 0 0 0;
  }

.soft-skills-container{
    width:40vw;
    padding:2vw;
    text-align: justify;

}

  
  .soft-skills{
    font-family:"IBM Plex Sans";
    list-style:none;
    font-weight: 600;
    font-size:2.25vh;
    padding:1.5vh;
    margin:1vw;
    width:fit-content;
    border-bottom: dotted .4vh blue;
    border-right: dotted .4vh blue;
  }
  .about-closing-statment{
    font-family:"IBM Plex Sans";
    font-size:1.75vh;
    font-weight: 500;
    width:20vw;
    margin:0 auto;
    padding:7vw;
    text-align: justify;
    z-index: 2000;
  }
  .dog-word{
    color:rgb(81, 81, 81);
    font-weight:800;
    font-size: 2vh;
  }

  .dog-word:hover{
    color:blue;
    font-weight:900;
    cursor: pointer;
  }
 
  .Luke{
    width:15vw;
    height:auto;
    z-index: 5000;
    padding:1vh;
    border:.25vh dotted blue;
    align-self:right;
    position:fixed;
    
  }
  .spotify-link{
    text-decoration: none;
    color:rgb(81,81,81);
    cursor: pointer;
    font-weight:900;
    font-size: 2vh;
  }
  .spotify-link:hover{
    color:blue;
    font-weight:700;
    cursor: pointer;

  }
  .about-closing-statment-2{
    font-family:"IBM Plex Sans";
    font-size:1.75vh;
    font-weight: 500;
    width:35vw;
    padding:7vh 0 0 1vh;
    text-align: justify;
    align-self:right;
    color:rgb(81,81,81);

  }
  .build{
    font-weight:900;
    color:blue;
  }

  @media (max-width: 450px) {
    .about-modal-content {
        width: 80vw; 
        height: 90vh; 
        padding:2vh;
    }
    .about-header-container{
      display:flex;
      flex-direction: column;
    }
    .about-header-body{
      font-size: 4vh;
      width:75vw;
      
    }
    .about-body-container{
      width:80vw;
    }
    .about-statment{
      width:75vw;
      margin:2.5vh 0 0 1vh;
    }
    .about-statment-2{
      margin:2vh 0 0 0;
      width:60vw;
    }
    .ram-quote{
      width:68vw;
    }
    .about-statment-3{
      width:68vw;
    }
    .skills-header{
      margin:6vh 0 6vh 0;
    }
    .skills-container{
      width:75vw;
      align-items: start;
      padding:3vh 0 2vh 0;
    }
    .skill-icon{
      width:auto;
      height:8vw;
      padding:1.5vw;
      
    }
    .skill-name {
      position: absolute;
      font-family: "IBM Plex Sans";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: black;
      background-color: rgb(255, 255, 255, 0.2);
      font-size:1.75vh;
      font-weight:600;
      transition: opacity 0.3s ease;
    }
    .soft-skills-container{
      width:65vw;
      padding:2vw;
      text-align: justify;
  
  }
  .about-closing-statment{
    width:50vw;
  }
  .Luke{
    width:50vw;
  }
  .about-closing-statment-2{
    width:50vw;
  }
  .open-about-modal{
      font-family:"IBM Plex Sans";
        font-size: 4vh;
        font-weight: 500;
  }
  }