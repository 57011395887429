

.hobbyr-wrapper{
    max-width: 100%;
}
.hobbyr-modal-content{
    display:flex;
    flex-direction: column;
}

.hobbyr-info-logo-container{
    width:fit-content;
    height:auto;
    padding:1vh 0 0 2vw;


}

.hobbyr-info-logo{
    height:6vh;
    width:auto;
}
.contributions{
    font-family: "IBM Plex Sans";
    color:rgb(149, 149, 149);
    font-size: 1.3vh;
    padding:1vw 0 0 1.5vw;
    width:fit-content;
}

.hobbyr-info{
    font-family: 'IBM Plex Sans';
    color:black;
    font-weight: 500;
    font-size:1.5vh;
    width:40vw;
    padding:2vh;
    text-align:justify;
}
.tech-stack-container{
    width:fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:2vh 0 0 2vh;
}
.react-logo{
    height:3vh;
    width:auto;
    padding: 0 .5vw;
}
.js-logo{
    height:3vh;
    width:auto;
    padding: 0 .5vw;
}
.django-logo{
    height:3vh;
    width:auto;
    padding:0 .5vw;
}
.python-logo{
    height:3vh;
    width:auto;
    padding:0 .5vw;
}


.hobbyr-login{
    height:50vh;
    width:auto;
    padding:1vw;
}
.hobbyr-browse{
    height:50vh;
    width:auto;
    padding:1vw;
}
.hobbyr-create{
    height:50vh;
    width:auto;
    padding:1vw;
}
.hobbyr-details{
    height:50vh;
    width:auto;
    padding:1vh;
}
.hobbyr-comment{
    height:50vh;
    width:auto;
    padding:1vh;
}

.hobbyr-ui-container{
    display:flex;
    flex-direction:row;
    width:45vw;
    overflow-x:scroll;
}

.hobbyr-ui-info-container{
    display: flex;
}

.hobbyr-ui-info{
    font-family:"IBM Plex Sans";
}
.ui-info{
    font-weight:500;
    font-size:1.7vh;
    padding:1vh 0 0 1vw;
}
.ui-info-2{
    list-style: none;
    font-size:1.5vh;
    font-weight:700;
    padding:1vh;
    margin:1vh;
    color:white;
    background-color: blue;
    width:fit-content;
}
.ui-info-3{
    list-style: none;
    font-size:1.5vh;
    font-weight:700;
    padding:1vh;
    margin:1vh;
    color:white;
    background-color: blue;
    width:fit-content;
}

.ui-info-4{
    list-style: none;
    font-size:1.5vh;
    font-weight:700;
    padding:1vh;
    margin:1vh;
    color:white;
    background-color: blue;
    width:fit-content;

}
.ui-info-5{
    list-style: none;
    font-size:1.5vh;
    font-weight:700;
    padding:1vh;
    margin:1vh;
    color:white;
    background-color: blue;
    width:fit-content;
}
.ui-info-statment{
    font-size:1.7vh;
    font-weight:500;
    padding:3vh 2vh 2vh 0vh;
    text-align: justify;
}
.ui-info-closing{
    font-size:1.5vh;
    font-weight:500;
    width:35vw;
    text-align: justify;
}
.git-hub-container{
    display: flex;
    justify-content: center;
    padding:2vh;

}

.hobbyr-github{
    width:5vw;
    height:auto;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.hobbyr-github:hover{
    width:6vw;
    height:auto;
    transition: width 0.5s ease-in-out;
}

@media (max-width: 450px) {
    .hobbyr-info{
        width:70vw;
    }
    .hobbyr-ui-container{
        width:80vw;
    }
}