.rekt-wrapper {
  max-width: 100%;
}
.rekt-modal-content {
  display: flex;
  flex-direction: column;
}
.rekt-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: auto;
}

.rekt-info-logo-container {
  width: fit-content;
  height: auto;
  padding: 1vh 0 0 0;
}

.rekt-info-logo {
  height: auto;
  width: 12vw;
  padding:.75vw;
}

.rekt-tech-stack-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 2vh 0 0;
}

.rekt-react-logo {
  height: 3vh;
  width: auto;
  padding: 0 0.5vw;
}
.rekt-mongo-logo {
  height: 3vh;
  width: auto;
  padding: 0 0.5vw;
}
.rekt-express-logo {
  height: 3vh;
  width: auto;
  padding: 0 0.5vw;
}
.rekt-node-logo {
  height: 3vh;
  width: auto;
  padding: 0 0.5vw;
}

.contributions{
    font-family: "IBM Plex Sans";
    color:rgb(149, 149, 149);
    font-size: 1.3vh;
    padding:1vw 0 0 1.5vw;
    width:fit-content;
}

.rekt-info-container {
  font-family: "IBM Plex Sans";
  color: black;
  font-weight: 500;
  font-size: 2vh;
  width: 40vw;
  padding: 1vh 2vh 2vh 2vh;
  text-align: justify;
}

.rekt-landing {
  width: 30vw;
  height: auto;
}
.rekt-landing:hover {
  width: 40vw;
  height: auto;
  transition: all 1s ease;
}

.rekt-ui-container {
  display: flex;
  flex-direction: row;
  width: 45vw;
  height: fit-content;
  overflow-x: scroll;
  padding: 2vw 0 0 0;
}
.REKT-browse {
  width: 40vw;
  height: auto;
}
.REKT-browse:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.rekt-details {
  width: 40vw;
  height: auto;
}
.rekt-details:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.rekt-reviews {
  width: 40vw;
  height: auto;
}
.rekt-reviews:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.add-review {
  width: 40vw;
  height: auto;
}
.add-review:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.my-review {
  width: 40vw;
  height: auto;
}
.my-review:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.my-favs {
  width: 40vw;
  height: auto;
}
.my-favs:hover {
  width: 50vw;
  height: auto;
  transition: all 1.3s ease;
}
.rekt-info-body {
  font-family: "IBM Plex Sans";
  color: black;
  font-weight: 550;
  font-size: 1.35vh;
  width: 43vw;
  padding: 2vh;
  text-align: justify;
}
.rekt-goals {
  font-family: "IBM Plex Sans";
  color: black;
  font-weight: 600;
  font-size: 1.5vh;
}

.goal-1 {
  font-size: 1.5vh;
  font-weight: 700;
  padding: 0.5vh;
  margin: 1vh;
  color: white;
  background-color: blue;
  width: fit-content;
  list-style: none;
}
.goal-2 {
  font-size: 1.5vh;
  font-weight: 700;
  padding: 0.5vh;
  margin: 1vh;
  color: white;
  background-color: blue;
  width: fit-content;
  list-style: none;
}
.goal-3 {
  font-size: 1.5vh;
  font-weight: 700;
  padding: 0.5vh;
  margin: 1vh;
  color: white;
  background-color: blue;
  width: fit-content;
  list-style: none;
}
.goal-4 {
  font-size: 1.5vh;
  font-weight: 700;
  padding: 0.5vh;
  margin: 1vh;
  color: white;
  background-color: blue;
  width: fit-content;
  list-style: none;
}
.rekt-info-body-2 {
  font-family: "IBM Plex Sans";
  color: black;
  font-weight: 500;
  font-size: 1.35vh;
  width: 30vw;
  padding: 2vh 0 0 5vw;
  text-align: justify;
}
.icons-header {
  font-family: "IBM Plex Sans";
  font-size: 2vh;
  font-weight: 700;
  padding: 1vh;
  margin: 2vh;
  color: blue;
  width: fit-content;
  list-style: none;
}
.rekt-icons-wrapper {
  width: 45vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rekt-icons-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1vh;
  margin: 1vh;
}
.heart-icon {
  width: 3vw;
  height: auto;
  padding: 1.5vh;
}
.plus-icon {
  width: 3vw;
  height: auto;
  padding: 1.5vh;
}
.star-icon {
  width: 3vw;
  height: auto;
  padding: 1.5vh;
}
.ux-header {
  font-size: 3vh;
  font-weight: 700;
  padding: 1vh;
  margin: 2vh;
  color: white;
  background-color: blue;
  width: fit-content;
}
.rekt-ui-info {
  font-family: "IBM Plex Sans";
  color: black;
  font-weight: 500;
  font-size: 1.35vh;
  width: 45vw;
  text-align: justify;
}
.logos-header {
  font-family: "IBM Plex Sans";
  font-size: 2vh;
  font-weight: 700;
  padding: 1vh;
  margin: 2vh;
  color: blue;
  width: fit-content;
}

.rekt-logos-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rekt-logos-container {
  display: flex;
  flex-direction: row;
  width: 45vw;
  height: fit-content;
  overflow-x: scroll;
  padding: 2vw 0 0 0;
  align-items: center;
}
.rekt-thumbnail-logo {
  height: 10vw;
  width: auto;
  padding: 1vh 2vw 1vh 2vw;
}
.rekt-logo-black {
  height: 10vw;
  width: auto;
  padding: 1vh 2vw 1vh 2vw;
}
.rekt-logo-neon-purp {
  height: 14vw;
  width: auto;
  padding: 1vw;
}
.rekt-logo-neon-pink {
  height: 14vw;
  width: auto;
  padding: 1vw;
}
.illustrations-header {
  font-family: "IBM Plex Sans";
  font-size: 2vh;
  font-weight: 700;
  padding: 1vh;
  margin: 2vh;
  color: blue;
  width: fit-content;
  list-style: none;
}
.illustrations-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.illustrations-container{
    display: flex;
    flex-direction: row;
    width: 45vw;
    height: fit-content;
    overflow-x: scroll;
    padding: 2vw 0 0 0;
    align-items: center;

}
.rekt-monster-1{
    height:16vh;
    width:auto;
    padding:1.5vw;
}
.rekt-monster-2{
    height:16vh;
    width:auto;
    padding:1.5vw;
}
.rekt-monster-3{
    height:16vh;
    width:auto;
    padding:1.5vw;
}

.rekt-links{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rekt-github{
    width:5vw;
    height:auto;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.rekt-github:hover{
    width:6vw;
    height:auto;
    transition: width 0.5s ease-in-out;
}
.rekt-website{
    width:5vw;
    height:auto;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.rekt-website:hover{
    width:6vw;
    height:auto;
    transition: width 0.5s ease-in-out;
}

@media (max-width: 450px) {

  .rekt-header-container{
    margin:0 0 0 5vw;
    padding:1vw;
  }

  .rekt-info-logo{
    width:30vw;
    margin:0 0 0 5vw;
  }

  .rekt-tech-stack-container{
    margin:0 0 0 5vw;
  }

  .rekt-info-container{
    width:75vw;
  }
  .rekt-landing{
    width:80vw;
  }
  .rekt-ui-container{
    width:80vw;
  }
  .REKT-browse {
    width: auto;
    height: 40vh;
  }
  .REKT-browse:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .rekt-details {
    width: auto;
    height: 40vh;
  }
  .rekt-details:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .rekt-reviews {
    width: auto;
    height: 40vh;
  }
  .rekt-reviews:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .add-review {
    width: auto;
    height: 40vh;
  }
  .add-review:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .my-review {
    width: auto;
    height: 40vh;
  }
  .my-review:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .my-favs {
    width: auto;
    height: 40vh;
  }
  .my-favs:hover {
    width: auto;
    height: 50vh;
    transition: all 1.3s ease;
  }
  .rekt-info-body{
    width:70vw;
    font-size:2.25vh;
    text-align: justify;
  }
  .rekt-goals{
    font-size:5vh;
    width:70vw;
    color:blue;
    padding:0 0 5vh 0;
  }
  .goal-1, .goal-2, .goal-3, .goal-4{
    color:white;
    background-color: rgb(149, 149, 149);
    font-size: 2vh;
    margin:3vh 0 2vh 0;
    padding:2vh;

  }
  .rekt-info-body-2{
    width:60vw;
    font-size: 2vh;
  }
  .ux-header{
    margin:3vh auto;
    color:blue;
    background-color: white;
    border-bottom:.5vh dotted blue;
    font-size: 8vh;
  }
  .rekt-ui-info{
    width:70vw;
    font-size:1.5vh;
  }
  .rekt-icons-wrapper{
    align-items: center;
    justify-content: center;
    width:80vw;
  }
  .heart-icon {
    width: 12vw;
    height: auto;
    padding: 2vh;
  }
  .plus-icon {
    width: 12vw;
    height: auto;
    padding: 2vh;
  }
  .star-icon {
    width: 12vw;
    height: auto;
    padding: 2vh;
  }
  .rekt-logos-container{
    width:80vw;
    align-items: center;
  }
  .rekt-thumbnail-logo {
    height: 26vw;
    width: auto;
    padding: 1vh 2vw 1vh 2vw;
  }
  .rekt-logo-black {
    height: 24vw;
    width: auto;
    padding: 1vh 2vw 1vh 2vw;
  }
  .rekt-logo-neon-purp {
    height: 34vw;
    width: auto;
    padding: 1.5vw;
  }
  .rekt-logo-neon-pink {
    height: 34vw;
    width: auto;
    padding: 1.5vw;
  }
  .illustrations-container{
    width:75vw;
  }
  .rekt-links{
    padding:8vh 0 0 3vh;
  }
  .rekt-github{
    width:10vw;
    height:auto;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
  }
  .rekt-github:hover{
      width:12vw;
      height:auto;
      transition: width 0.5s ease-in-out;
  }
  .rekt-website{
      width:10vw;
      height:auto;
      filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
  }
  .rekt-website:hover{
      width:12vw;
      height:auto;
      transition: width 0.5s ease-in-out;
  }
}
