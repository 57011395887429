body.active-modal {
    overflow-y: hidden;
}

.project-info-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1005; 
}

.project-info-modal.active {
    display: block;
}

.project-info-modal-content {
    position: relative;
    z-index: 1002; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding:1vw 1vw 1vw 3vw;
    box-shadow: 0 0 10px rgba(167, 167, 167, 0.2);
    width:50vw;
    height:50vw;
    display: flex;
    overflow-y:auto;
}
@media (max-width: 450px) {
    .project-info-modal-content{
        width:80vw;
        height:90vh;
    }
}

.project-info-modal-content .project-info-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;

}

.project-info-close{
    font-family: "IBM Plex Sans";
    border:none;
    background-color: white;
    color:rgb(183, 183, 183);
    font-size: 3.5vh;
    font-weight:400;
    margin-right: 1vw;

}
.project-info-close:hover{
    color:black;
    font-weight: 800;
    transition: all 1s ease;

}





