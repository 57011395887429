body.active-modal {
    overflow-y: hidden;
}

.projects-modal, .projects-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.projects-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;  
    background: rgba(255, 254, 254, 0.5);
    z-index: 5;
}
.projects-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 7vw;
    width: fit-content;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 15vh 0 0 0;
    z-index: 10;
    background-color: white;
    opacity: 0;
    
    transition: opacity 1s ease-in;
  }
  .projects-modal-content.animate-in {
    opacity: 1;
  }



.close-projects-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.projects-open-modal{
    font-family:"IBM Plex Sans";
    font-size: 3em;
    font-weight: 500;
}
.projects-open-modal:hover{
    color:white;
    background-color:blue;
    cursor: pointer;
    transition:color 0.5s, background-color 0.5s; 
}



.rekt-logo-container{
    width:10vw;
    height:auto;
    padding:1vh;
    display: flex;
    justify-content: center;
} 
.rekt-logo-container:hover{
    width:13vw;
    transition:all 0.7s ease-in-out;
}
.open-rekt{
    width:50%;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.open-rekt:hover{
    filter:none;
}



.hobbyr-logo-container{
    width:15vw;
    height:auto;
    padding:2vh;
    display: flex;
    justify-content: center;
}
.hobbyr-logo-container:hover{
    width:17vw;
    transition:all 0.7s ease-in-out;
}
.open-hobbyr{
    width:100%;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.open-hobbyr:hover{
    filter:none;
}



.dinaj-logo-container{
    width:15vw;
    height:auto;
    padding:2vh;
    display: flex;
    justify-content: center;
}
.dinaj-logo-container:hover{
    width:17vw;
    transition:all 0.7s ease-in-out;
}

.open-dinaj{
    width:100%;
    filter: invert(8%) sepia(98%) saturate(7139%) hue-rotate(247deg) brightness(98%) contrast(145%);
}
.open-dinaj:hover{
    filter: none;
}


@media (max-width: 450px) {
    .projects-modal-content {
        flex-direction: column;
        width:75vw;
        height:80vh;
    }
    .rekt-logo-container{
        width:40vw;
        padding:0;
    }
    .hobbyr-logo-container{
        width:40vw;
        padding:0;
    }
    .dinaj-logo-container{
        width:40vw;

    }
    .projects-open-modal{
        font-family:"IBM Plex Sans";
        font-size: 4vh;
        font-weight: 500;
    }
  }






