body.active-modal {
    overflow-y: hidden;
}

.contact-modal, .contact-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}


.contact-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;  
    background: rgba(255, 254, 254, 0.4);
    z-index: 5;
}

.contact-modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(167, 167, 167, 0.2);
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 45vw;
    min-width: 300px;
    z-index: 20;
}

.close-projects-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.open-contact-modal{
    font-family:"IBM Plex Sans";
    font-size:3em;
    font-weight: 500;
}
.open-contact-modal:hover{
    color:white;
    background-color:blue;
    cursor: pointer;
    transition:color 0.5s, background-color 0.5s; 
}

.contact-container{
    width:45vw;
    height:fit-content;
}
.contact-header-container{
    display: flex;
    flex-direction: row;
    width:45vw;
    height:fit-content;
    justify-content: space-between;
    align-items: center;
}

.contact-header{
        font-family:"IBM Plex Sans";
        font-weight:700;
        font-size:4vh;
        color:white;
        background-color: blue;
        width:fit-content;
        height:auto;
        padding:1vh;
}
.close-contact-modal{
    font-family: "IBM Plex Sans";
    border:none;
    background-color: white;
    color:rgb(183, 183, 183);
    font-size: 3.5vh;
    font-weight:400;
}
.close-contact-modal:hover{
    color:black;
    font-weight: 800;
    transition: all 1s ease;

}
.contact-info-header{
    padding:8vh;
    font-family:"IBM Plex Sans";
    font-size:2vh;
    text-align: justify;
    font-weight:500;
    border-bottom:.5vh dotted blue;
    width:25vw;
    margin:2vh auto;
}

.personal-github{
    width:3vw;
    padding:1vh;
    height:auto;
}
.personal-github:hover{
    width:4vw;
    height:auto;
    transition: width 0.5s ease-in-out;
}
.linkedin{
    width:3vw;
    padding:1vh;
    height:auto;

}
.linkedin:hover{
    width:4vw;
    height:auto;
    transition: width 0.5s ease-in-out;

}
.socials{
    margin:0 auto;
    display:flex;
    flex-direction: column;

}
.socials-title{
    font-family:"IBM Plex Sans";
    font-weight:800;
    font-size:3vh;
    color:white;
    background-color: blue;
    width:fit-content;
    padding:1vh;
    align-self: center;
}
.socials-container{
    display:flex;
    flex-direction:row;
    justify-content: center;
}
@media (max-width: 450px) {
    .contact-header-container{
        width:75vw;
        
    }
    .contact-container{
        width:75vw;
        height:60vh;
    }
    .contact-info-header{
        width:50vw;
        font-size: 2vh;
        border:none;
        justify-self: left;
    }
    .personal-github{
        width:9vw;
        padding:1vh;
        height:auto;
    }
    .personal-github:hover{
        width:10vw;
        height:auto;
        transition: width 0.5s ease-in-out;
    }
    .linkedin{
        width:9vw;
        padding:1vh;
        height:auto;
    
    }
    .linkedin:hover{
        width:10vw;
        height:auto;
        transition: width 0.5s ease-in-out;
    
    }
    .open-contact-modal{
        font-family:"IBM Plex Sans";
        font-size: 4vh;
        font-weight: 500;
    }
}
