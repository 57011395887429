

.app-wrapper{
  width:100%;
  height:100%;
  overflow: hidden;
}
.header-container{
  width:90vw;
  padding:10vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.header-name{
  font-family:"IBM Plex Sans";
  padding:0 3vw 0 0;
  font-size:13vh;
  font-weight: 900;
  color:blue;
}

.app-header-title{
  font-family:"IBM Plex Sans";
  font-size:7vh;
  font-weight:700;
  padding:0 3vw 0 0;
  color:blue;
}
.section-container{
  width:fit-content;
  padding:3em;
}
@media (max-width: 450px) {
  .app-wrapper{
    width:100vw;
    height:auto;
  }
  .header-container {
      width:75vw; 
      padding:1vh;
      display:flexbox;
      flex-direction: column;
      flex-wrap: row wrap;
      margin:0 auto;

  }
  .app-header-title{
    font-size: 2.5vh;
    font-weight:800;
    background-color: blue;
    color:white;
    padding:1vh;
  
  }
  .header-name {
    font-size: 7.5vh; 
    padding:1vh;
  }
  .section-container{
    margin:25vh 0 0 0;
  }
}
